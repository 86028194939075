<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top py-3">
      <h3 class="text-center mb-2">Tolong temukan Psikolog untuk saya di:</h3>
      <MapPshycolog v-bind:role="role" />
    </div>
  </div>
</template>

<script>
import MapPshycolog from "../../General/mapPshycolog.vue";
export default {
  name: "homePsy",
  components: { MapPshycolog },
  data() {
    return {
      role: true,
    };
  },
};
</script>

<style scoped></style>
